<template>
  <div ref="element">
  <TitleBarPenawaran :title-stack="titleStack" @clicked="kembaliKeProyek"/>
  <transition name="fade">
    <div v-show="!viewState" class="container is-max-desktop">
      <!-- Pilih Client -->
      <div class="input-card" style="margin-top: 20px;">
        <b-field label="Client" v-if="newPenawaran && newPenawaran.project_name">
          <div class="columns m-3">
            <input class="input" v-model="newPenawaran.project_name" type="text" disabled/>
          </div>
        </b-field>
        <b-field label="Client" v-else>
          <b-select placeholder="Select Client" expanded v-model="clientSelect">
            <option v-for="(item, index) in clientOptions" :value="item" :key="index">{{item.name}}</option>
          </b-select>
        </b-field>
      </div>
      <!-- Card Pilih PIC -->
      <div class="input-card" style="margin-top: 20px;">
        <div><span class="has-text-weight-bold">PIC</span></div>
        <notification class="is-info mt-2">
          <span class="has-text-weight-bold">Jika sudah menentukan PIC,
          infokan penawaran kepada PIC yang telah dipilih!
          </span>
        </notification>
        <div class="columns" v-if="newPenawaran && newPenawaran.customer_name" style="padding-top: 10px;">
          <div class="column is-four-fifths">
            <h1 class="title is-5"> {{ newPenawaran.customer_name }} ( {{ newPenawaran.cell_phone }} ) </h1>
          </div>
          <div class="column">
            <b-button type="is-success" icon-left="account" @click="customerIsView=true">Ubah</b-button>
          </div>
        </div>
        <div style="text-align: center; margin-top: 30px;">
          <b-button v-if='!newPenawaran || !newPenawaran.customer_name' @click.prevent="customerIsView=true"
            type="is-success" style="width: 300px;" icon-left="account-alert-outline">
            Tentukan PIC
          </b-button>
        </div>
      </div>
      <!-- Card Pilih Mitra -->
      <div class="input-card" style="margin-top: 20px;">
        <div class="columns is-mobile">
          <div class="column">
            <div class="has-text-weight-bold">Pilih Tukang</div>
            <div class="dropdown-box">
              <b-dropdown
              append-to-body
              scrollable
              expanded
              max-height="450px"
              mobile-modal
              @change="skillIsSelected"
              v-model="currentSkill"
              aria-role="list">
              <template #trigger>
              <b-button
                label="Pilih tukang yang anda butuhkan"
                type="is-light"
                icon-right="menu-down"/>
              </template>
              <b-dropdown-item custom aria-role="listitem">
                <b-input v-model="searchSkill" placeholder="search" expanded />
              </b-dropdown-item>
              <b-dropdown-item
                v-for="(item, index) in filteredDataSkill" :key="index" :value="item" aria-role="listitem">
                <div class="media" @click.prevent="showModalSesi()">
                  <img :src="item.image_uri" class="media-left" style="width: 50px;" />
                    <div class="media-content">
                    <h3>{{item.name}}</h3>
                  </div>
                </div>
              </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="columns is-mobile mt-3 is-align-content-center">
              <div class="column is-full-desktop is-two-fifths-mobile">
                <div class="table-container">
                  <table class="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>Keahlian</th>
                        <th class="has-text-centered">Sesi</th>
                        <th class="has-text-centered">Jumlah Tukang</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(lay, idx) in newPenawaran.workerDetails" :key="idx">
                        <td>
                          <div class="media">
                            <img :src="lay.image_uri" class="media-left" style="max-width: 15%;" />
                            <div class="media-content is-align-self-center">
                              <h3>{{ lay.skill_name }}</h3>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p class="has-text-centered is-align-self-center">{{ lay.sesi_name }}</p>
                        </td>
                        <td>
                          <p class="has-text-centered is-align-self-center">{{ lay.qty }}</p>
                        </td>
                        <td>
                          <b-button @click="deleteSkill(idx)" type="is-danger" icon-left="delete" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Card Pilih Material -->
      <div class="input-card" style="margin-top: 20px;">
        <div class="columns">
          <div class="column">
            <div class="has-text-weight-bold"> Pilih Material </div>
            <div class="dropdown-box">
              <b-dropdown
                append-to-body
                scrollable
                expanded
                max-height="450px"
                mobile-modal
                @change="productIsSelected"
                v-model="currentProduct"
                aria-role="list">
                <template #trigger>
                <b-button
                label="Pilih material yang anda butuhkan"
                type="is-light"
                icon-right="menu-down"/>
                </template>
                <b-dropdown-item custom aria-role="listitem">
                  <b-input v-model="searchProduct" placeholder="search" expanded />
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="(item, index) in productFilterData" :key="index" :value="item" aria-role="listitem">
                  <div class="media" @click.prevent="showModalProduct()">
                    <img :src="item.image_uri" class="media-left" style="max-width: 50px;" />
                    <div class="media-content">
                      <h3>{{item.name}}</h3>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="columns is-mobile mt-3 is-align-content-center">
              <div class="column is-two-fifths-mobile">
                <div class="table-container">
                  <table class="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>Material</th>
                        <th class="has-text-centered">Jumlah</th>
                        <th class="has-text-centered">Price</th>
                        <th class="has-text-centered">Unit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(lay, idx) in newPenawaran.materialDetails" :key="idx">
                        <td style="max-width: 100px">
                          <div class="media">
                            <img :src="lay.product_image" class="media-left" style="max-width: 20%;" />
                            <div class="media-content is-align-self-center">
                              <h3>{{ lay.product_name }}</h3>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p class="has-text-centered is-align-self-center">{{ lay.qty }}</p>
                        </td>
                        <td>
                          <p class="has-text-centered is-align-self-center">Rp.{{ lay.price | numFormat }}</p>
                        </td>
                        <td>
                          <p class="has-text-centered is-align-self-center">{{ lay.unit }}</p>
                        </td>
                        <td>
                          <b-button @click="productListRemove(idx)" type="is-danger" icon-left="delete" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Card Deskripsi Pekerjaan -->
      <div class="input-card" style="margin-top: 20px;">
        <div class="has-text-weight-bold"> Deskripsi </div>
        <div style="margin-top: 25px;">
          <b-input
            v-model="description"
            :value="descriptions"
            rows="2"
            maxlength="200"
            minlength="10"
            type="textarea">
          </b-input>
        </div>
      </div>
      <!-- Card Jumlah Hari -->
      <div class="input-card" style="margin-top: 20px;">
        <b-field label="Jumlah Hari Pengerjaan">
          <b-numberinput
            :min="1"
            :value="dataOfDuration"
            v-model="durationDay"
            controls-rounded
            rounded>
          </b-numberinput>
        </b-field>
      </div>
      <!-- Card Pilih Alamat -->
      <div class="input-card" style="margin-top: 20px;">
        <div class="columns">
          <div class="column">
            <div class="has-text-weight-bold"> Alamat </div>
            <div style="display: flex;" v-if="newPenawaran && newPenawaran.address">
              <div style="padding-top: 20px; width: 60%;">
                <div style="margin-right: 20px;" v-if="newPenawaran && newPenawaran.latitude && newPenawaran.longitude">
                  <GmapMap class="googleMapsView" map-type-id="roadmap" :zoom="17" :center="map.currentPlace" :options="{disableDefaultUI: true}">
                    <GmapMarker :position="map.currentPlace">
                    </GmapMarker>
                  </GmapMap>
                </div>
                <div style="padding: 20px 20px 0px 0px;">
                  {{ newPenawaran.address }}
                <br/>
                  Bangunan: <strong>{{ newPenawaran.jenis_bangunan }}</strong>
                </div>
              </div>
              <div class="ml-5" style="width: 7em; padding-top: 20px;">
                <b-button type="is-info" tag="input" native-type="submit" value="Ubah"
                class="button is-outlined" @click="modal.tentukanAlamatIsView=true" expanded/>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div style="text-align: center; margin-top: 40px;">
            <b-button v-if="!newPenawaran || !newPenawaran.address" @click="showAlamat()"
              type="is-success" style="width: 300px;" icon-left="map-marker"> Tentukan Alamat </b-button>
            </div>
          </div>
        </div>
      </div>
      <!-- Card Biaya Parkir -->
      <div class="input-card" style="margin-top: 20px;">
        <b-field label="Total Biaya Parkir">
          <b-input type="number" v-model="biayaParkir" placeholder="Masukan Nominal"></b-input>
        </b-field>
      </div>
      <!-- Biaya PPh -->
      <div class="input-card" style="margin-top: 20px;">
        <b-field label="Biaya PPh">
          <b-checkbox size="is-small" v-model="isPph" value="1">
            <span class="is-size-6">Termasuk Biaya PPh (2%)</span>
          </b-checkbox>
        </b-field>
      </div>
      <div style="text-align: center; margin-top: 40px; margin-bottom: 40px;">
        <b-button @click.prevent="createProjectConfirm()" type="is-primary" style="width: 300px;" icon-right="arrow-right">
          Lanjutkan
        </b-button>
      </div>
    </div>
  </transition>

  <!-- Screen Konfirm Penawaran -->
  <transition name="fade">
    <div v-show="viewState" class="input-card container is-max-desktop">
      <section>
        <div class="columns is-mobile">
          <div class="column">
            <b-button @click="backCreateProject()" type="is-success" icon-right="arrow-left" />
          </div>
          <div class="column">
            <p class="title is-4 is-size-6-mobile mt-3 has-text-right">Konfirmasi Penawaran</p>
          </div>
        </div>
      </section>
      <div class="mt-4"> Client </div>
      <div class="mt-2">
        <h1 class="title is-5 is-size-6-mobile">{{ newPenawaran.project_name }}</h1>
      </div>
      <div class="mt-4"> PIC </div>
      <div class="mt-2">
        <h1 class="title is-5 is-size-6-mobile">{{ newPenawaran.customer_name }}</h1>
      </div>
      <div class="mt-4"> Alamat Pengerjaan </div>
      <div class="mt-2">
        <h1 class="title is-5 is-size-6-mobile">{{ newPenawaran.address }}</h1>
        <div v-if="newPenawaran && newPenawaran.jenis_bangunan">
          Tipe Bangunan: <span class="has-text-weight-bold is-size-5 is-size-6-mobile">{{ newPenawaran.jenis_bangunan }}</span>
        </div>
      </div>
      <div v-if="newPenawaran && newPenawaran.address">
        <div style="padding-top: 20px; padding-bottom: 20px;">
          <div v-if="newPenawaran && newPenawaran.latitude && newPenawaran.longitude">
            <GmapMap map-type-id="roadmap" :zoom="15"
              :center="map.currentPlace"
              :options="{disableDefaultUI: true}"
              style="width: 100%; height: 200px;">
              <GmapMarker :position="map.currentPlace">
              </GmapMarker>
            </GmapMap>
          </div>
        </div>
      </div>
      <div class="mt-4"> Deskripsi</div>
      <div class="mt-2">
        <h1 class="title is-5 is-size-6-mobile">{{ newPenawaran.description }}</h1>
      </div>
      <div class="mt-5 has-text-weight-bold">Rincian Kebutuhan Tukang</div>
      <div style="margin-top: 10px;">
        <div class="table-container">
          <table class="table is-bordered is-fullwidth">
            <thead class="has-text-centered">
              <tr>
                <td class="has-text-weight-bold">Keahlian</td>
                <!-- <td class="has-text-weight-bold">Sesi</td> -->
                <td class="has-text-weight-bold">Jumlah Tukang</td>
                <td class="has-text-weight-bold">Harga</td>
                <td class="has-text-weight-bold">Total</td>
              </tr>
            </thead>
            <!-- dibuat sort by tanggal -->
            <tbody v-if="dataCalculation !== null">
              <tr v-for="(vv, ii) in dataCalculation.workerDetails" :key="ii">
                <td>{{ vv.skill_name }}</td>
                <!-- <td>{{ vv.sesi }}</td> -->
                <td class="has-text-centered">{{ vv.qty }}</td>
                <td>Rp.{{ vv.price | numFormat }}</td>
                <td>Rp.{{ vv.amount | numFormat }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-5 has-text-weight-bold">Rincian Kebutuhan Material</div>
      <div style="margin-top: 10px;">
        <div class="table-container">
          <table class="table is-bordered is-fullwidth">
            <thead class="has-text-centered">
              <tr>
                <td class="has-text-weight-bold">Material</td>
                <td class="has-text-weight-bold">Qty</td>
                <td class="has-text-weight-bold">UOM</td>
                <td class="has-text-weight-bold">Total</td>
              </tr>
            </thead>
            <tbody v-if="dataCalculation !== null">
              <tr v-for="(vv, ii) in dataCalculation.materialDetails" :key="ii">
                <td>{{ vv.product_name }}</td>
                <td class="has-text-centered">{{ vv.qty }}</td>
                <td class="has-text-centered">{{ vv.unit }}</td>
                <td>Rp.{{ vv.price | numFormat }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="has-text-right" v-if="dataCalculation !== null">
        <div class="mt-5"> Grand Total </div>
        <div class="mt-3">
          <div class="title is-5">
            Rp.{{ dataCalculation.amount | numFormat }}
          </div>
        </div>
      </div>
      <div style="text-align: center; margin-top: 40px; margin-bottom: 40px;">
        <b-button @click.prevent="createPenawaran()" type="is-primary" style="width: 300px;"> Buat Penawaran </b-button>
      </div>
    </div>
  </transition>

  <!-- modal pilih customer -->
  <ModalBase :is-active="customerIsView" @cancel="closeModalPIC">
    <template v-slot:header>
      <h6 style="font-weight: bold">Tentukan PIC</h6>
    </template>
    <template v-slot:body>
      <project-customer @catchvalue="insertCustomer" />
    </template>
  </ModalBase>

  <!-- modal sesi di skill -->
  <b-modal
    v-model="modal.skillSesi"
    aria-role="dialog"
    has-modal-card
    trap-focus
    @cancel="closeModalSkill"
    :can-cancel="false"
    aria-modal>
    <div class="modal-card" style="background-color:#ffffff">
      <header class="modal-card-head">
        <p class="modal-card-title"> Tentukan Sesi Dan Jumlah Tukang</p>
        <button type="button" class="delete" @click="closeModalSkill()"/>
      </header>
      <div class="modal-card-body">
        <div class="content">
          <div class="columns is-mobile is-justify-content-center" v-for="(lay) in skillsSelected" :key="lay.id">
            <div class="column is-8 is-12-mobile">
              <div class="media mb-4">
                <img :src="lay.image_uri" class="media-left" style="width: 20%;" />
                <div class="media-content is-align-self-center">
                  <span class="is-size-6 is-size-7-mobile has-text-weight-bold">{{ lay.name }}</span>
                </div>
              </div>
              <b-field label="Pilih Sesi">
                <b-select
                  v-model="sesiId"
                  placeholder="Select a name">
                  <option
                    v-for="option in sessionSel"
                    :value="option.id"
                    :key="option.id">
                    {{ option.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Jumlah Tukang">
                <b-field>
                  <b-numberinput size="is-small" :min="1" v-model="qtyTukang" controls-rounded></b-numberinput>
                </b-field>
              </b-field>
              <div class="mt-5" >
                <b-button @click="pilihSkill(lay)" type="is-info" icon-left="check-bold" expanded> Pilih </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>

  <!-- modal add product -->
  <b-modal
    v-model="modal.pilihProductIsView"
    aria-role="dialog"
    has-modal-card
    trap-focus
    @cancel="closeModalProduct"
    :can-cancel="false"
    aria-modal>
    <div class="modal-card" style="background-color:#ffffff">
      <header class="modal-card-head">
        <p class="modal-card-title"> Tentukan Material Dan Jumlah</p>
        <button type="button" class="delete" @click="closeModalProduct()"/>
      </header>
      <div class="modal-card-body">
        <div class="content">
          <div class="columns is-mobile is-justify-content-center" v-for="(lay) in productSelected" :key="lay.id">
            <div class="column is-8 is-12-mobile">
              <div class="media">
                <img :src="lay.image_uri" class="media-left" style="width: 25%;" />
                <div class="media-content is-align-self-center">
                  <span class="is-size-6 is-size-7-mobile has-text-weight-bold">{{ lay.name }}</span>
                </div>
              </div>
              <b-field label="Harga (Harga Keseluruhan)">
                <b-input size="is-small" type="number" v-model="hargaProduct"></b-input>
              </b-field>
              <b-field label="Kuantitas">
                <b-field>
                  <b-numberinput size="is-small" :min="1" v-model="qtyProduct" controls-rounded></b-numberinput>
                </b-field>
              </b-field>
              <b-field label="UOM">
                <b-input size="is-small" v-model="unitProduct"></b-input>
              </b-field>
              <div class="mt-5">
                <b-button @click="pilihProduct(lay)" type="is-info" icon-left="check-bold" expanded> Pilih </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>

  <!-- modal pilih alamat pengerjaan -->
  <b-modal
    v-model="modal.tentukanAlamatIsView"
    aria-role="dialog"
    @cancel="closeModalAlamat">
    <div class="card" style="background-color:#ffffff">
      <header class="card-header">
        <p class="card-header-title"> Tentukan Alamat </p>
      </header>
      <div class="card-content">
        <div class="content">
          <b-field label="Lokasi di Peta">
            <div class="columns is-mobile">
              <div class="column">
                <b-button class="is-primary is-outlined m-2" @click="mapOnViewClick()" type="is-success" icon-left="map-marker-plus"> Pilih Lokasi </b-button>
                <div v-if="map.currentPlace.lat && map.mapSelectedIsShow">
                  <GmapMap style="max-width: 100%; height: 200px;" map-type-id="roadmap" :zoom="15"
                    :center="map.currentPlace"
                    :options="{disableDefaultUI: true}">
                    <GmapMarker :position="map.currentPlace"></GmapMarker>
                  </GmapMap>
                </div>
              </div>
            </div>
          </b-field>
          <b-field label="Alamat">
            <b-input class="has-text-black has-text-weight-bold" disabled v-model="modal.addressRef" minlength="10" maxlength="200" type="textarea"></b-input>
          </b-field>
          <b-field label="Deskripsi Lokasi">
            <b-input v-model="modal.address" minlength="10" maxlength="200" type="textarea"></b-input>
          </b-field>
          <b-field label="Jenis Bangunan">
            <div class="tile is-ancestor">
              <div class="tile is-parent" v-for="(v, i) in buildingType" :key="i">
                <article class="tile is-child box" @click="setBuildingType(i)" :class="{'jenis-bangunan-selected': v.isSelect === true}">
                  <p class="subtitle">
                    <img class="image is-32x32 centerImgs" :src="v.image_uri" />
                  </p>
                  <p class="subtitle has-text-centered">{{ v.name }}</p>
                </article>
              </div>
            </div>
          </b-field>
          <div class="content" style="text-align: right; margin-top: 15px;">
            <b-button @click="insertAddress()" style="width: 200px;"
              :type="{'is-light': modal.address === '' && map.currentPlace.lat === null,
                'is-success' : modal.address !== '' && map.currentPlace.lat !== null }">
              Simpan
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>

  <!-- Google Maps -->
  <b-modal v-model="modal.isMapOnView">
    <div class="card" style="background-color: #ffffff">
      <div class="card-content">
        <b-field label="Cari Alamat">
          <b-autocomplete
            :data="map.address"
            placeholder="Telusuri Alamat"
            field="title"
            :loading="map.isFetchingAddress"
            @typing="getAddress"
            @select="option => map.addressSelected = option">
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.name }}
                  <br>
                  <small> {{ props.option.address }} </small>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
        <GmapMap map-type-id="roadmap" :zoom="15"
          :center="map.gmapCenter"
          style="width: auto; height: 400px">
          <GmapInfoWindow :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
            <div> <button @click="simpanLokasiOnClick()" class="button is-success"> Simpan Lokasi </button> </div>
          </GmapInfoWindow>
          <GmapMarker :position="map.markerPosition" :clickable="true"
            @dragend="updateCoordinate"
            @click="mapMarkerOnClick()"
            :draggable="true">
          </GmapMarker>
        </GmapMap>
      </div>
    </div>
  </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
// import moment from 'moment'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import ModalBase from '@/components/ModalBase.vue'
import ProjectCustomer from '../project/Customer.vue'
import Notification from '@/components/Notification.vue'
import TitleBarPenawaran from '@/components/TitleBarCreateOffer'

export default {
  name: 'FormPenawaran',
  components: {
    TitleBarPenawaran,
    Notification,
    ModalBase,
    ProjectCustomer
  },
  data () {
    return {
      sessionSel: [],
      days: [],
      viewState: false,
      customerIsView: false,
      modal: {
        tentukanAlamatIsView: false,
        isTentukanJadwal: false,
        isSesi: false,
        pilihProductIsView: false,
        isMapOnView: false,
        dateColumn: 2,
        isPenugasanTukangIsView: false,
        transactionProducts: [],
        address: '',
        addressRef: '',
        listProductIsView: false,
        isSaldoTidakCukup: false,
        skillSesi: false
      },
      productToDo: [],
      productServiceRelated: [],
      productSelected: [],
      pilihProductOnEdit: false,
      pilihProductEditIndex: -1,
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      map: {
        currentPlace: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        markerPosition: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        address: null,
        isFetchingAddress: false,
        addressSelected: null,
        gmapCenter: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        mapSelectedIsShow: false
      },
      biayaParkir: '',
      buildingType: [],
      isCustomerNew: false,
      project: null,
      minDateOrder: null,
      skills: [],
      currentSkill: null,
      skillsSelected: [],
      sesiSkills: [],
      dataCalculation: null,
      paymentInfo: null,
      isPph: 0,
      clientSelect: '',
      clientOptions: [],
      searchSkill: '',
      qtyTukang: 0,
      sesiId: null,
      products: [],
      searchProduct: '',
      currentProduct: null,
      qtyProduct: 0,
      hargaProduct: 0,
      unitProduct: null,
      description: null,
      durationDay: 0,
      idOffer: null
    }
  },
  watch: {
    biayaParkir (newVal, oldVal) {
      this.$store.commit('penawaran/setBiayaParkir', newVal.trim())
    },
    'map.addressSelected' (newVal, oldVal) {
      this.refreshAllCoordinate(newVal)
    },
    clientSelect (newVal, oldVal) {
      this.$store.commit('penawaran/setIsClient', newVal.id)
      this.$store.commit('penawaran/setProjectName', newVal.name)
    }
  },
  computed: {
    titleStack () {
      const update = ['Penawaran', 'Update Penawaran']
      const create = ['Penawaran', 'Create Penawaran']
      const data = this.$route.params.idPenawaran ? update : create
      return data
    },
    ...mapGetters({
      user: 'auth/user',
      url: 'setup/getUrl',
      newPenawaran: 'penawaran/getNewPenawaran'
    }),
    productFilterData () {
      const searchProduct = this.searchProduct.toLowerCase()
      return this.products.filter(item => typeof item.name === 'string' && item.name.toLowerCase().includes(searchProduct))
    },
    filteredDataSkill () {
      const search = this.searchSkill.toLowerCase()
      return this.skills.filter(item => typeof item.name === 'string' && item.name.toLowerCase().includes(search))
    },
    workerDetails () {
      const detailWorkers = this.newPenawaran.workerDetails
      return detailWorkers
    },
    dataOfDuration () {
      let data
      if (this.newPenawaran) {
        data = this.newPenawaran.duration
      }

      return data
    },
    descriptions () {
      let data
      if (this.newPenawaran) {
        data = this.newPenawaran.description
      }

      return data
    }
  },
  methods: {
    // ALL GET API
    async loadClient () {
      await axios.get(this.url + '/api/v1/vendor/client?page=1&limit=1000')
        .then((v) => {
          if (v.data.data.data.length > 0) {
            this.clientOptions = v.data.data.data
          }
        })
        .catch((error) => {
          throw new Error(error)
        })
    },
    async loadSkillList () {
      await axios.get(this.url + '/api/v1/vendor/skill?page=1&limit=1000')
        .then((cb) => {
          if (cb.data.code === 1) {
            this.skills = cb.data.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async loadProduct () {
      const url = `${this.url}/api/v1/vendor/product?page=1&limit=1000`
      await axios.get(url)
        .then((cb) => {
          if (cb.data.code === 1) {
            this.products = cb.data.data.list
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getAddress (val) {
      await axios.get(`${this.url}/api/v1/vendor/map?place=${val}`)
        .then((cb) => {
          if (cb.data.code === 1) {
            this.map.address = cb.data.data
          }
        })
    },
    async loadBuildingType () {
      await axios.get(`${this.url}/api/v1/vendor/building_type`)
        .then((cb) => {
          const x = []
          cb.data.data.forEach((rw) => {
            const r = rw
            r.isSelect = false
            x.push(r)
          })
          this.buildingType = x
        })
        .catch((err) => {
          console.log(err)
        })
    },
    createProjectConfirm () {
      if (this.newPenawaran === undefined) { return }

      if (this.newPenawaran.customer_name.trim() === '' ||
        this.newPenawaran.jenis_bangunan.trim() === '' ||
        this.newPenawaran.address.trim() === '' ||
        this.newPenawaran.client_id === null ||
        this.newPenawaran.project_name.trim() === '') {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Mohon lengkapi dulu data yang harus diinput',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      if (this.durationDay === 0) {
        this.$buefy.toast.open({
          duration: 4000,
          message: 'Masukan jumlah hari kerja!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      this.$store.commit('penawaran/setInfoDate', { duration: this.durationDay })

      if (this.description === null) {
        this.$buefy.toast.open({
          duration: 4000,
          message: 'Mohon informasi <b>Deskripsi</b> diisi dulu!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      this.$store.commit('penawaran/setDescription', this.description)

      const vip = this.isPph
      if (vip === true) {
        this.$store.commit('penawaran/setIsPph', 1)
      } else {
        this.$store.commit('penawaran/setIsPph', 0)
      }
      this.$store.commit('penawaran/setInfo', {
        user_id: this.user.id,
        transaction_id: uuidv4()
      })

      if (this.biayaParkir.trim() === '') {
        this.$buefy.toast.open({
          duration: 4000,
          message: 'Mohon informasi <b>Biaya Parkir</b> diisi dulu',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element
      })

      setTimeout(() => {
        axios.post(`${this.url}/api/v1/vendor/offers/calculation`, this.newPenawaran)
          .then((dt) => {
            this.dataCalculation = dt.data.data
            this.viewState = !this.viewState
          })
          .catch((err) => {
            console.log(err.response.data.message)
          })
        loadingComponent.close()
      }, 2 * 1000)

      // this.viewState = !this.viewState
    },
    async createPenawaran () {
      if (this.processing === true) {
        console.log('test async, tolak')
      }

      const obj = this.newPenawaran
      delete obj.materialDetails
      delete obj.workerDetails

      // set the async state
      this.processing = true
      if (this.idOffer !== null) {
        axios.put(`${this.url}/api/v1/vendor/offers/${this.idOffer}`, obj)
          .then((res) => {
            this.$buefy.notification.open({
              message: 'Success Update The Offer!',
              duration: 3000,
              progressBar: true,
              type: 'is-success',
              pauseOnHover: true
            })
            this.$store.dispatch('penawaran/resetState')
            this.$router.push({ name: 'Home' })
          })
          .catch((err) => {
            this.$buefy.notification.open({
              duration: 4000,
              message: err.response.data.message,
              position: 'is-bottom-right',
              type: 'is-danger',
              hasIcon: true
            })
            this.$store.dispatch('penawaran/resetState')
            this.$router.push({ name: 'Home' })
          })
      } else {
        axios.post(`${this.url}/api/v1/vendor/offer`, obj)
          .then((res) => {
            if (res.data.code === 1) {
              this.$buefy.snackbar.open({
                message: res.data.message,
                queue: false
              }, 1500)
              this.$store.dispatch('penawaran/resetState')
              this.$router.push({ name: 'Home' })
            }
          })
          .catch((err) => {
            this.$buefy.toast.open({
              duration: 4000,
              message: err.response.data.message,
              position: 'is-bottom',
              type: 'is-danger'
            })
          })
      }
    },
    getDetails (idPenawaran) {
      try {
        if (idPenawaran === null) { return }
        const loadingComponent = this.$buefy.loading.open({
          container: this.isFullPage ? null : this.$refs.element
        })
        setTimeout(() => {
          loadingComponent.close()
          axios.get(`${this.url}/api/v1/vendor/offers/${idPenawaran}/detail`)
            .then((response) => {
              if (response.data.code === 1) {
                this.idOffer = response.data.data.id
                this.$store.commit('penawaran/setUpdatePenawaran', response.data.data)

                const v = JSON.parse(JSON.stringify(...response.data.data.worker_details))
                const resMaterial = JSON.parse(JSON.stringify(...response.data.data.material_details))
                const d = {
                  skill_id: v.skill_id,
                  qty: v.qty,
                  sesi_id: v.sesi_id
                }
                this.$store.commit('penawaran/setWorkers', d)

                const resultMaterial = {
                  product_id: resMaterial.product_id,
                  qty: resMaterial.qty,
                  price: +resMaterial.price,
                  unit: resMaterial.unit
                }
                this.$store.commit('penawaran/setMaterials', resultMaterial)
              }
            })
            .catch(error => {
              console.log(error)
            })
        }, 3 * 1000)
      } catch (error) {
        throw new Error(error)
      }
    },
    // CLOSE ALL GET API

    // MODAL
    closeModalPIC () {
      this.customerIsView = false
    },
    closeModalSkill () {
      this.modal.skillSesi = false
      this.skillsSelected = []
    },
    closeModalProduct () {
      this.modal.pilihProductIsView = false
      this.productSelected = []
    },
    showModalProduct () {
      this.modal.pilihProductIsView = true
    },
    showModalSesi () {
      this.modal.skillSesi = true
    },
    showAlamat () {
      this.modal.tentukanAlamatIsView = true
    },
    closeModalAlamat () {
      this.modal.tentukanAlamatIsView = false
      this.$store.commit('penawaran/clearAddress')
    },
    // CLOSE MODAL

    // COMMIT DATA STATE
    insertCustomer (val) {
      this.$store.commit('penawaran/setCustomer', val)
      this.$store.commit('penawaran/setIdCustomer', val.id)
      this.customerIsView = false
    },
    // CLOSE COMMIT DATA STATE

    // MAPS
    mapOnViewClick () {
      if (this.map.mapSelectedIsShow === true) {
        const x = JSON.parse(JSON.stringify(this.map.currentPlace))
        this.map.markerPosition = x
        this.map.gmapCenter = x
      }
      this.infoWinOpen = false
      this.modal.isMapOnView = true
    },
    updateCoordinate (location) {
      this.map.currentPlace.lat = location.latLng.lat()
      this.map.currentPlace.lng = location.latLng.lng()
      this.getAddressMap(location)
    },
    refreshAllCoordinate (newVal) {
      this.infoWinOpen = false
      this.map.gmapCenter = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      this.map.markerPosition = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      // this.updateCoordinate()
      this.map.currentPlace.lat = newVal.lat
      this.map.currentPlace.lng = newVal.lon
      const latLngObj = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
      })
    },
    getAddressMap (location) {
      // const geocoder = this.google.maps.Geocoder()
      const latLngObj = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
        // console.info(response.results)
      })
    },
    mapMarkerOnClick () {
      this.infoWindowPos = this.map.currentPlace
      // this.infoOptions.content = '<div> <button @click="simpanLokasiOnClick()" class="button is-success"> Simpan Lokasi </button> </div>'
      this.infoWinOpen = !this.infoWinOpen

      const latLngObj = {
        lat: this.map.markerPosition.lat,
        lng: this.map.markerPosition.lng
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.modal.addressRef = response.results[0].formatted_address
      })
      this.map.mapSelectedIsShow = true
      // this.modal.isMapOnView = false
    },
    simpanLokasiOnClick () {
      this.modal.isMapOnView = false
    },
    // CLOSE MAPS

    insertAddress () {
      if (this.modal.address.trim() === '') {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Harap detail <b>alamat customer</b> diisi dulu',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      let jenisBangunan = null
      let buildingId = null
      this.buildingType.forEach((rw, _idx) => {
        if (rw.isSelect === true) {
          jenisBangunan = rw.name
          buildingId = rw.id
        }
      })

      const addr = {
        address: this.modal.addressRef + ' + ' + this.modal.address,
        lat: this.map.currentPlace.lat,
        lng: this.map.currentPlace.lng,
        jenis_bangunan: jenisBangunan,
        building_id: buildingId
      }

      if (addr.jenis_bangunan == null) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Jenis Bangunan Harus Pilih!!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      this.$store.commit('penawaran/setAddress', addr)
      this.modal.tentukanAlamatIsView = false
    },
    setBuildingType (idx) {
      this.buildingType.forEach((rw, _idx) => {
        const changeIsSelect = idx === _idx ? this.buildingType[_idx].isSelect = !this.buildingType[_idx].isSelect : this.buildingType[_idx].isSelect = false
        return changeIsSelect
      })
    },
    productListRemove (index) {
      this.$store.commit('penawaran/deleteSetMaterials', index)
      this.$store.commit('penawaran/deleteSetDetailMaterial', index)
      this.productSelected = []
      this.$buefy.toast.open({
        duration: 2500,
        message: 'Hapus Material Berhasil!',
        position: 'is-bottom',
        type: 'is-info'
      })
    },
    deleteSkill (idx) {
      this.$store.commit('penawaran/deleteSetWorkers', idx)
      this.$store.commit('penawaran/clearSetDetailWorkers', idx)
      this.skillsSelected = []
      this.$buefy.toast.open({
        duration: 2500,
        message: 'Hapus Tukang Berhasil!',
        position: 'is-bottom',
        type: 'is-info'
      })
    },
    clearInputSkill () {
      this.qtyTukang = 0
      this.sesiId = null
    },
    clearInputProduk () {
      this.qtyProduct = 0
      this.hargaProduct = 0
      this.unitProduct = null
    },
    pilihSkill (lay) {
      if (this.qtyTukang === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'jumlah tukang tidak boleh kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      const data = {
        skill_id: lay.id,
        qty: this.qtyTukang,
        sesi_id: this.sesiId
      }
      this.$store.commit('penawaran/setWorkers', data)

      const arryWorkerDetail = []
      const skillSelect = JSON.parse(JSON.stringify(lay))

      this.skillsSelected.forEach((item) => {
        item.sesi.forEach((element) => {
          if (element.id === this.sesiId) {
            skillSelect.sesi_name = element.name
          }
        })
        if (item.id === skillSelect.id) {
          skillSelect.qty = data.qty
          arryWorkerDetail.push(skillSelect)
        }
      })
      const result = arryWorkerDetail.map(({ name, ...e }) => ({ ...e, skill_name: name }))
      this.$store.commit('penawaran/setDetailWorkers', result)

      this.skillsSelected = []
      this.sessionSel = []
      this.clearInputSkill()
      this.modal.skillSesi = false
    },
    skillIsSelected (val) {
      const sel = JSON.parse(JSON.stringify(val))
      let hasSelected = false
      this.skillsSelected.forEach((rw) => {
        if (rw.id === sel.id) {
          hasSelected = true
        }
      })

      if (hasSelected === false) {
        sel.tukang = 1
        this.skillsSelected.push(sel)
        sel.sesi.forEach((item) => {
          this.sessionSel.push(item)
        })
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: `${sel.name} sudah pernah dipilih`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    },
    pilihProduct (value) {
      if (value.qtyProduct === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Kuantitas material tidak boleh kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      if (value.hargaProduct === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Harga material tidak boleh kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      const data = {
        product_id: value.id,
        qty: this.qtyProduct,
        price: +this.hargaProduct,
        unit: this.unitProduct
      }
      this.$store.commit('penawaran/setMaterials', data)

      const arryProductDetail = []
      const productSelect = JSON.parse(JSON.stringify(value))

      this.productSelected.forEach((item) => {
        if (item.id === productSelect.id) {
          productSelect.qty = data.qty
          productSelect.price = data.price
          productSelect.unit = data.unit
          productSelect.product_image = item.image_uri
          arryProductDetail.push(productSelect)
        }
      })
      const result = arryProductDetail.map(({ name, ...e }) => ({ ...e, product_name: name }))
      this.$store.commit('penawaran/setDetailMaterial', result)

      this.productSelected = []
      this.clearInputProduk()
      this.modal.pilihProductIsView = false
    },
    productIsSelected (val) {
      const sel = JSON.parse(JSON.stringify(val))
      let hasSelected = false
      this.productSelected.forEach((rw) => {
        if (rw.id === sel.id) {
          hasSelected = true
        }
      })

      if (hasSelected === false) {
        sel.tukang = 1
        this.productSelected.push(sel)
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: `${sel.name} sudah pernah dipilih`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    },
    backCreateProject () {
      if (this.newPenawaran === undefined) {
        this.$buefy.snackbar.open({
          message: 'Error Back Project!',
          queue: false
        }, 1500)
      } else {
        this.dataCalculation = null
        this.viewState = !this.viewState
      }
    },
    kembaliKeProyek (event) {
      this.$buefy.dialog.confirm({
        message: `Batalkan ${event}`,
        onConfirm: () => this.$router.push({ name: 'Penawaran' })
      })
    }
  },
  created () {
    if (this.$route.params.idPenawaran) {
      this.getDetails(this.$route.params.idPenawaran)
    }
    this.$store.dispatch('penawaran/setNewPenawaran')
  },
  mounted () {
    this.loadClient()
    this.loadSkillList()
    this.loadProduct()
    this.loadBuildingType()
  }
}
</script>

<style lang="scss" scoped>
.dropdown-box {
  margin-top: 10px;
  border: 1px solid grey;
  border-radius: 2px;
  div, button {
    width: 100%;
    text-align: left;
  }
}
.input-card {
  padding: 20px 25px 20px 25px;
  margin-bottom: 20px;

  background: rgba(255, 255, 255, 0.23);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 1px solid rgba(255, 255, 255, 0.65);
}
.jenis-bangunan {
  cursor: pointer;
  &:hover {
    background-color: rgb(196, 196, 196);
  }
}
.jenis-bangunan-selected {
  border-radius: 20px;
  background-color: rgb(196, 196, 196);
}
.googleMapsView {
  max-width: 100%;
  height: 200px;
}
</style>
